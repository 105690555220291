<template>
  <div class="">
    <el-row class="mb-3">
      <el-col
        :span="8"
        :offset="8"
      >
        <el-card
          :body-style="{ padding: '10px' }"
          shadow="always"
        >
          <div
            v-if="this.userData"
            class="media"
          >
            <img
              src="@/assets/images/avatars/user.png"
              class="mr-3"
              style="width: 80px"
            >
            <div class="media-body">
              <h5 class="mt-0">
                {{ this.userData.name }}
              </h5>
              <h6>{{ this.userData.mobile }}</h6>
              <h6>{{ this.userData.email }}</h6>
              <h6>{{ this.userData.address }}</h6>
            </div>
          </div>
          <div
            v-else
            class="text-center"
          >
            No Data Found
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-form
      v-if="this.userData"
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="220px"
      class="demo-ruleForm"
      size="mini"
    >
      <el-card class="box-card">
        <div
          slot="header"
          class="clearfix"
        >
          <span>Server & Billing Information</span>
        </div>

        <el-row>
          <el-col :span="8">
            <el-form-item
              label="Connection Date"
              prop="connection_date"
            >
              <el-date-picker
                v-model="ruleForm.connection_date"
                type="date"
                placeholder="Connection Date"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="Connection Title"
              prop="title"
            >
              <el-input
                v-model="ruleForm.title"
                placeholder="Home/Office/Shop"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="Connection Type"
              prop="type"
            >
              <el-radio-group v-model="ruleForm.connection_type">
                <el-radio-button
                  label="static"
                  disabled
                >
                  Static
                </el-radio-button>
                <el-radio-button label="pppoe">
                  PPPoE
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="Billing Package"
              prop="package_id"
            >
              <el-select
                v-model="ruleForm.package_id"
                placeholder="Package"
              >
                <el-option
                  v-for="pkg in package_list"
                  :key="pkg.value"
                  :label="pkg.label"
                  :value="pkg.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="MikroTik Router"
              prop="router_id"
            >
              <el-select
                v-model="ruleForm.router_id"
                placeholder="MikroTik Router"
              >
                <el-option
                  v-for="router in router_list"
                  :key="router.value"
                  :label="router.label"
                  :value="router.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="Profile"
              prop="mikrotik_profile"
            >
              <el-select
                v-model="ruleForm.mikrotik_profile"
                placeholder="MikroTik Profile"
                :loading="loadingProfile"
              >
                <el-option
                  v-for="profile in profiles"
                  :key="profile"
                  :label="profile"
                  :value="profile"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="Secrets > Name"
              prop="user_name"
            >
              <el-input
                v-model="ruleForm.user_name"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="Password"
              prop="password"
            >
              <el-input v-model="ruleForm.password" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="PPPoE Remote Address"
              prop="remote_address"
            >
              <el-input v-model="ruleForm.remote_address" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="Mac"
              prop="mac"
            >
              <el-input v-model="ruleForm.mac" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="Comment"
              prop="comment"
            >
              <el-input v-model="ruleForm.comment" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="Monthly Bill"
              prop="package_price"
            >
              <el-input-number
                v-model="ruleForm.package_price"
                :min="0"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="Vat (%)"
              prop="vat"
            >
              <el-input-number
                v-model="ruleForm.vat"
                :min="0"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="Total"
              prop="total"
            >
              <el-input
                v-model="ruleForm.total"
                disabled
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="box-card mt-2">
        <div
          slot="header"
          class="clearfix"
        >
          <span>Personal Information</span>
        </div>

        <el-row>
          <el-col :span="12">
            <el-form-item
              label="Customer / Company Name"
              prop="customer_name"
            >
              <el-input v-model="ruleForm.customer_name" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="Contact Person"
              prop="contact_person"
            >
              <el-input v-model="ruleForm.contact_person" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item
              label="Email"
              prop="email"
            >
              <el-input v-model="ruleForm.email" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="Identification No"
              prop="identity_no"
            >
              <el-input v-model="ruleForm.identity_no" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item
              label="Mobile No"
              prop="mobile_no"
            >
              <el-input v-model="ruleForm.mobile_no" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="Alternative Mobile No"
              prop="alternative_mobile_no"
            >
              <el-input v-model="ruleForm.alternative_mobile_no" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item
              label="Professional Detail"
              prop="professional_detail"
            >
              <el-input v-model="ruleForm.professional_detail" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="box-card mt-2">
        <div
          slot="header"
          class="clearfix"
        >
          <span>Address</span>
        </div>

        <el-row>
          <!-- Division Dropdown -->
          <el-col :span="6">
            <el-form-item
              label="Division"
              prop="division"
            >
              <el-select
                v-model="ruleForm.division"
                placeholder="Select Division"
              >
                <el-option
                  v-for="division in division_list"
                  :key="division.value"
                  :label="division.label"
                  :value="division.value"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <!-- District Dropdown -->
          <el-col :span="6">
            <el-form-item
              label="District"
              prop="district"
            >
              <el-select
                v-model="ruleForm.district"
                placeholder="Select District"
                :disabled="!ruleForm.division"
              >
                <el-option
                  v-for="district in district_list"
                  :key="district.value"
                  :label="district.label"
                  :value="district.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!-- Upazila Dropdown -->
          <el-col :span="6">
            <el-form-item
              label="Upazila"
              prop="upazila"
            >
              <el-select
                v-model="ruleForm.upazila"
                placeholder="Select Upazila"
                :disabled="!ruleForm.district"
              >
                <el-option
                  v-for="upazila in upazila_list"
                  :key="upazila.value"
                  :label="upazila.label"
                  :value="upazila.value"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <!-- Thana Dropdown -->
          <el-col :span="6">
            <el-form-item
              label="Thana"
              prop="thana"
            >
              <el-select
                v-model="ruleForm.thana"
                placeholder="Select Thana"
                :disabled="!ruleForm.upazila"
              >
                <el-option
                  v-for="thana in thana_list"
                  :key="thana.value"
                  :label="thana.label"
                  :value="thana.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="Area"
              prop="area"
            >
              <el-select
                v-model="ruleForm.area"
                placeholder="Select area"
              >
                <el-option
                  v-for="area in areas"
                  :key="area.id"
                  :label="area.name"
                  :value="String(area.id)"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="Building Name"
              prop="building_name"
            >
              <el-input v-model="ruleForm.building_name" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="House No"
              prop="house_no"
            >
              <el-input v-model="ruleForm.house_no" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item
              label="Floor"
              prop="floor"
            >
              <el-select v-model="ruleForm.floor">
                <el-option
                  label="1st"
                  value="1st"
                />
                <el-option
                  label="2nd"
                  value="2nd"
                />
                <el-option
                  label="3rd"
                  value="3rd"
                />
                <el-option
                  label="4th"
                  value="4th"
                />
                <el-option
                  label="5th"
                  value="5th"
                />
                <el-option
                  label="6th"
                  value="6th"
                />
                <el-option
                  label="7th"
                  value="7th"
                />
                <el-option
                  label="8th"
                  value="8th"
                />
                <el-option
                  label="9th"
                  value="9th"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="House Extra Info"
              prop="house_extra_info"
            >
              <el-input v-model="ruleForm.house_extra_info" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item
              label="Latitude"
              prop="latitude"
            >
              <el-input v-model="ruleForm.latitude" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="Longitude"
              prop="longitude"
            >
              <el-input v-model="ruleForm.longitude" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="box-card mt-2">
        <div
          slot="header"
          class="clearfix"
        >
          <span>Official Information</span>
        </div>

        <el-row>
          <el-col :span="8">
            <el-form-item
              label="Billing Type"
              prop="billing_type"
            >
              <el-radio-group v-model="ruleForm.billing_type">
                <el-radio label="pre_paid">
                  Pre Paid
                </el-radio>
                <el-radio label="post_paid">
                  Post paid
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="Type of Connection"
              prop="type_of_connection"
            >
              <el-radio-group v-model="ruleForm.type_of_connection">
                <el-radio label="wired">
                  Wired
                </el-radio>
                <el-radio label="wireless">
                  Wireless
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="Type of Connectivity"
              prop="type_of_connectivity"
            >
              <el-radio-group v-model="ruleForm.type_of_connectivity">
                <el-radio label="shared">
                  Shared
                </el-radio>
                <el-radio label="dedicated">
                  Dedicated
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item
              label="Type of Client"
              prop="type_of_client"
            >
              <el-select v-model="ruleForm.type_of_client">
                <el-option
                  label="Home"
                  value="home"
                />
                <el-option
                  label="Corporate"
                  value="corporate"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="Distribution Location Point"
              prop="distribution_location_point"
            >
              <el-select v-model="ruleForm.distribution_location_point">
                <el-option
                  label="DC"
                  value="dc"
                />
                <el-option
                  label="NOC"
                  value="noc"
                />
                <el-option
                  label="POP"
                  value="pop"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="Description"
              prop="description"
            >
              <el-input v-model="ruleForm.description" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="Note"
              prop="note"
            >
              <el-input v-model="ruleForm.note" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="Connected By"
              prop="connected_by"
            >
              <el-input v-model="ruleForm.connected_by" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="Security Deposit"
              prop="security_deposit"
            >
              <el-input v-model="ruleForm.security_deposit" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="box-card mt-2 text-center">
        <el-button
          type="primary"
          @click="handleUpdate('ruleForm')"
        >
          Update
        </el-button>
      </el-card>
    </el-form>
  </div>
</template>
<script>
import {
  ISP_PACKAGE_LIST,
  ISP_ROUTER_LIST,
  PAIR_CODE_FIND,
  DIVISION_LIST,
  DISTRICT_LIST,
  UPAZILA_LIST,
  THANA_LIST, USERNAME_VIEW, USERNAME_VIEW_V2,
} from '@core/services/api'
import { checkRes } from '@core/services/helper'
import useJwt from '@/auth/jwt/useJwt'

export default {
  data() {
    return {
      isp_id: JSON.parse(localStorage.getItem('userData'))?.isp_id,
      pair_code: null,
      userData: null,
      user_id: null,
      loadingProfile: false,
      package_list: [],
      router_list: [],
      profiles: [],
      areas: [],
      division_list: [],
      district_list: [],
      upazila_list: [],
      thana_list: [],

      ruleForm: {
        title: null,
        router_id: null,
        package_price: 0,
        connection_date: null,
        connection_type: 'pppoe',
        mikrotik_profile: null,
        mac: null,
        user_name: null,
        password: null,
        comment: null,
        remote_address: null,
        package_id: null,
        vat: 0,
        total: null,
        customer_name: null,
        contact_person: null,
        email: null,
        identity_no: null,
        mobile_no: null,
        alternative_mobile_no: null,
        professional_detail: null,
        area: null,
        building_name: null,
        house_no: null,
        floor: null,
        house_extra_info: null,
        latitude: null,
        longitude: null,
        billing_type: 'pre_paid',
        type_of_connection: 'wired',
        type_of_connectivity: 'shared',
        type_of_client: 'home',
        distribution_location_point: 'pop',
        description: null,
        note: null,
        connected_by: null,
        security_deposit: null,
        division: null,
        district: null,
        upazila: null,
        thana: null,
      },
      rules: {
        package_id: [
          {
            required: true,
            message: 'Please Select a Package',
            trigger: 'blur',
          },
        ],
        package_price: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur',
          },
        ],
        router_id: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur',
          },
        ],
        title: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur',
          },
        ],
        user_name: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  watch: {
    'ruleForm.router_id': function (newVal) {
      this.handleProfile(newVal)
    },
    'ruleForm.package_id': function (newVal) {
      const selectedPackage = this.package_list.find(
        pkg => pkg.value === newVal,
      )
      this.ruleForm.package_price = selectedPackage ? selectedPackage.price : 0
      this.updateTotalPrice()
    },
    'ruleForm.vat': function () {
      this.updateTotalPrice()
    },
    'ruleForm.division': function (newVal) {
      this.fetchDistricts(newVal)
    },
    'ruleForm.district': function (newVal) {
      this.fetchUpazilas(newVal)
    },
    'ruleForm.upazila': function (newVal) {
      this.fetchThanas(newVal)
    },
  },
  mounted() {
    this.packageListData(this.isp_id)
    this.routerListData(this.isp_id)
    this.fetchDivisions()
    this.showUserDetail()

    this.fetchAreas()
  },
  methods: {
    showUserDetail() {
      if (this.$route.params && this.$route.params.id) {
        this.getUserInfo(this.$route.params.id)
      }
    },
    updateTotalPrice() {
      const { package_price: price, vat } = this.ruleForm
      if (price > 0 && vat > 0) {
        this.ruleForm.total = price + price * (vat / 100)
      } else {
        this.ruleForm.total = price // if no VAT or invalid, total is just the package price
      }
    },
    getUserInfo(id) {
      this.$http.get(process.env.VUE_APP_BASEURL + USERNAME_VIEW_V2 + id)
        .then(res => {
          this.ruleForm = res.data.data
          this.userData = res.data.data.users
          console.log('ruleform', this.ruleForm)
          if (!checkRes(res.data.code) && !this.userData) {
            this.toastMessage('warning', 'Assign Dail Up', res)
          }
        })
    },

    packageListData(ispId) {
      this.$http
        .get(
          `${process.env.VUE_APP_BASEURL + ISP_PACKAGE_LIST}?isp_id=${ispId}`,
        )
        .then(res => {
          this.package_list = res.data.data
        })
    },
    routerListData(ispId) {
      this.$http
        .get(`${process.env.VUE_APP_BASEURL + ISP_ROUTER_LIST}?isp_id=${ispId}`)
        .then(res => {
          this.router_list = res.data.data
        })
    },
    handleUpdate(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const payload = this.ruleForm
          payload.user_id = this.userData.id
          payload.status = 1
          payload.autogenerate = 1

          console.log('payload', payload)
          useJwt.updateCustomerUserName(this.$route.params.id, payload).then(res => {
            if (res.data.code === 200) {
              this.$notify({
                type: 'success',
                message: res.data.messages,
              })
            } else {
              this.$notify({
                type: 'error',
                message: res.data.messages,
              })
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
        return false
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    handleProfile(routerId) {
      this.loadingProfile = true
      if (routerId) {
        const url = `${process.env.VUE_APP_BASEURL}/api/get-ppp-profiles/${routerId}`
        this.$http
          .get(url)
          .then(res => {
            if (res.data.code === 200) {
              this.profiles = res.data.data.profiles
            } else {
              this.profiles = []
            }
            this.loadingProfile = false
          })
          .finally(() => {
            this.loadingProfile = false
          })
      }
    },
    // Fetch all divisions from the API
    fetchDivisions() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + DIVISION_LIST)
        .then(res => {
          this.division_list = res.data.data
        })
        .catch(error => {
          console.error('Error fetching divisions:', error)
        })
    },

    // Fetch districts based on selected division
    fetchDistricts(divisionId) {
      // this.district_list = [] // Reset the district dropdown
      // this.upazila_list = [] // Reset the upazila dropdown
      // this.thana_list = [] // Reset the thana dropdown
      // this.ruleForm.district = null
      // this.ruleForm.upazila = null
      // this.ruleForm.thana = null

      if (divisionId) {
        this.$http.get(process.env.VUE_APP_BASEURL + DISTRICT_LIST + divisionId)
          .then(res => {
            this.district_list = res.data.data
          })
          .catch(error => {
            console.error('Error fetching districts:', error)
          })
      }
    },

    // Fetch upazilas based on selected district
    fetchUpazilas(districtId) {
      // this.upazila_list = [] // Reset the upazila dropdown
      // this.thana_list = [] // Reset the thana dropdown
      // this.ruleForm.upazila = null
      // this.ruleForm.thana = null

      if (districtId) {
        this.$http.get(process.env.VUE_APP_BASEURL + UPAZILA_LIST + districtId)
          .then(res => {
            this.upazila_list = res.data.data
          })
          .catch(error => {
            console.error('Error fetching upazilas:', error)
          })
      }
    },

    // Fetch thanas based on selected upazila
    fetchThanas(upazilaId) {
      this.thana_list = [] // Reset the thana dropdown
      // this.ruleForm.thana = null

      if (upazilaId) {
        this.$http.get(process.env.VUE_APP_BASEURL + THANA_LIST + upazilaId)
          .then(res => {
            this.thana_list = res.data.data
          })
          .catch(error => {
            console.error('Error fetching thanas:', error)
          })
      }
    },
    fetchAreas() {
      this.$http
        .get(`${process.env.VUE_APP_BASEURL}/api/areas?isp_id=${this.isp_id}`)
        .then(res => {
          this.areas = res.data.data
        })
    },
  },
}
</script>
